<template>
    <div class="container flex flex-col">
        <div class="container flex flex-col p-0">
            <router-view :key="$route.name === 'user-list' ? $route.name : $route.fullPath"></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: "Admin",
}
</script>
